<template>
    <div class="profile_notes" v-if="modelValue">
        <div class="edit_modal">
            <div class="edit_section setting_wpr p-0">
                <div class="form_body">
                    <h2 class="title_header">Points Redeem<button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button></h2>
                    <Form @submit="handleRedeemPoints" v-slot='{ errors }'>
                        <div class="form_content">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Points Redeemed</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.points }">
                                        <Field autocomplete="off" type="number" name="points" v-model="form.points" placeholder="0" :rules="{ 'between': [1, totalPoints], 'required': true, 'numeric': true }" />
                                    </div>
                                    <ErrorMessage name="points" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Points Remaining</label>
                                    <div class="field_wpr">
                                        <input type="text" :value="totalPoints" readonly>
                                    </div>
                                </div>
                            </div>
                            <label for="allow" class="switch_option capsule_btn">
                                <h5>Contact Notification</h5>
                                    <input type="checkbox" id="allow" v-model="form.subscriber_notifications" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <div v-show="form.subscriber_notifications == 1">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">You Just Redeem {{ form.points }} Points for</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.reason }">
                                            <Field autocomplete="off" type="text" name="reason" v-model="form.reason" placeholder="type here.." :rules="{ 'required': form.subscriber_notifications == 1 }"/>
                                        </div>
                                        <ErrorMessage name="reason" class="text-danger" />
                                    </div>
                                </div>
                                <h5 class="mt-3">Notification Method</h5>
                                <div class="edit_section bg-light mt-2 mb-5">
                                    <sending-method v-model="form.notification_method" />
                                </div>
                            </div>
                            <div class="action_wpr">
                                <button :disabled="rewardLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                                <button :disabled="rewardLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="rewardLoader"></i> {{ rewardLoader ? ' Saving' : 'Save'}}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        data () {
            return {
                form: {
                    subscriber_notifications: 1,
                    notification_method: 1,
                    reason: '',
                    points: '',
                    contact_id: '',
                },
            }
        },

        props: {
            modelValue: Boolean,
            contact: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.form = {
                        subscriber_notifications: 1,
                        notification_method: 1,
                        reason: '',
                        points: '',
                        contact_id: ''
                    };
                }
            },
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            rewardLoader: state => state.contactModule.rewardLoader,
            totalPoints: state => state.contactModule.rewardsTotalPoints,
        }),

        methods: {
            ...mapActions({
                redeemPoints: 'contactModule/redeemPoints',
                getContactReward: 'contactModule/getContactReward',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            handleRedeemPoints (form, { setFieldError }) {
                const vm = this;

                vm.form.contact_id    = vm.contact.id;
                vm.form.setFieldError = setFieldError;

                vm.redeemPoints(vm.form).then((result) => {
                    if (result) {
                        const params = {
                                            page: 1,
                                            per_page: 6,
                                            contact_id: vm.contact.id,
                                        };

                        vm.getContactReward(params);
                        vm.closeModal();
                    }
                });
            },
        }
    }
</script>

<style scoped>
.edit_modal .edit_section .form_body .form_content{
    padding-bottom: 0;
}
</style>
